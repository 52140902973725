import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import { MyEstadoGlobalContext } from './MyEstadoGlobalContext';

export default function Header(props) {
  const { onSkin, placeholder, setIdq, setOrigenIn, setPantalla4 } = props;

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const { mostrarWSE, setMostrarWSE } = React.useContext(MyEstadoGlobalContext);
  const { mostrarBD, setMostrarBD } = React.useContext(MyEstadoGlobalContext);
  const { mostrarHome, setMostrarHome } = React.useContext(MyEstadoGlobalContext); // Agregado para controlar el estado de Home

  const [query, setQuery] = useState("");

  const ClickBoton = (e) => {
    console.log("ClickBoton ejecutado con:", e); // Depuración

    setPantalla4(e);
    setOrigenIn("SE");

    // Asegurarnos de que el contenido de "Home" se oculte
    setMostrarWSE(true);  // Mostrar los resultados de búsqueda
    setMostrarBD(false);   // Ocultar cualquier otra cosa como BD
    setMostrarHome(false); // Ocultar el Home (agregado)
    
    console.log("EN Header - Query:", e); // Depuración
    console.log("ESTADO DEL WSE DESDE EL HEADER", mostrarWSE);
  };

  const ClickBoton2 = (e) => {
    setMostrarWSE(false);  // Mostrar los resultados de búsqueda
    console.log("ESTADO DEL WSE DESDE EL HEADER", mostrarWSE);
  };

  const handleKeyDown = (event) => {
    console.log("Tecla presionada:", event.key); // Depuración
    if (event.key === 'Enter') {
      // En lugar de usar event.preventDefault(), lo removemos para que no interfiera
      console.log("Enter detectado - ejecutando ClickBoton con:", query); // Depuración
      ClickBoton(query); // Ejecutar la función de búsqueda
    }
  };

  useEffect(() => {
    setMostrarBD(false);
  }, [setMostrarBD]);

  return (
    <div className="header-main px-3 px-lg-4 mb-3">
      <Link onClick={(e) => e.preventDefault()} className="menu-link me-10 me-lg-4">
        <i className="ri-menu-2-fill"></i>
      </Link>

      <div className="form-search me-auto d-flex h-10 w-75">
        <input
          type="text"
          className="form-control"
          placeholder={placeholder}
          value={query}
          onChange={(event) => setQuery(event.target.value)} // Actualizar el valor del input
          onKeyDown={handleKeyDown} // Capturar la tecla Enter
        />
        <i className="ri-search-line"></i>
        <Link to="/">
          <ButtonDefaultLight onClick={() => ClickBoton(query)}>
            <Buscar>Buscar</Buscar>
          </ButtonDefaultLight>
        </Link>
      </div>

      <Dropdown className="dropdown-skin" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <i className="ri-settings-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <label>Skin</label>
          <nav className="nav nav-skin">
            <Link onClick={(e) => e.preventDefault()} className="nav-link">Light</Link>
            <Link onClick={(e) => e.preventDefault()} className="nav-link">Dark</Link>
          </nav>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

const ButtonDefaultLight = styled.div`
  margin-top: 1px;
  display: flex;
  padding: 8.5px 15px;
  align-items: flex-start;
  min-width: 82px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #000000;
  transition: all 0.2s ease;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    transform: translate(0, -5px);
    box-shadow: 0px 12px 5px -8px rgba(0, 0, 0, 0.2);
  }
`;

const Buscar = styled.div`
  min-height: 19px;
  font-family: var(--font-family-helveticaneue-regular);
  font-weight: 400;
  color: var(--blue);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
`;
